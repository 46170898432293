import React, { useState, useEffect, useRef } from 'react';
import { 
  Box, Typography, Button, Card, CardContent, CardMedia, 
  Radio, RadioGroup, FormControlLabel, FormControl, FormLabel,
  LinearProgress, Alert
} from '@mui/material';
import { styled } from '@mui/material/styles';

const GameCard = styled(Card)(({ theme }) => ({
  maxWidth: 345,
  margin: '0 auto',
  marginTop: theme.spacing(4),
}));

const SpellIcon = styled(CardMedia)(({ theme }) => ({
  height: 105, // 75% of the original 140px height
  width: 105, // 75% of the original 140px width
  backgroundSize: 'contain',
  margin: '0 auto', // Center the icon
}));

const ClassSpecIcon = styled('img')({
  width: 30, // 75% of the original 40px width
  height: 30, // 75% of the original 40px height
  marginRight: 8,
});

const drCategories = [
  'none',
  'stun',
  'incapacitate',
  'disorient',
  'silence',
  'root',
  'disarm',
  'knockback'
];

const MAX_ROUNDS = 10;
const MAX_WRONG_ANSWERS = 3;
const MAX_ROUND_DURATION = 5000; // 5 seconds in milliseconds

export default function DRTrainingGame({ spells }) {
  const [currentSpell, setCurrentSpell] = useState(null);
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [score, setScore] = useState(0);
  const [roundsPlayed, setRoundsPlayed] = useState(0);
  const [wrongAnswers, setWrongAnswers] = useState(0);
  const [gameOver, setGameOver] = useState(false);
  const [gameStarted, setGameStarted] = useState(false);
  const [timeLeft, setTimeLeft] = useState(MAX_ROUND_DURATION);
  const [selectedAnswer, setSelectedAnswer] = useState('');
  const [feedback, setFeedback] = useState(null);
  const [showingFinalFeedback, setShowingFinalFeedback] = useState(false);
  const [answerSubmitted, setAnswerSubmitted] = useState(false);
  const [validSpells, setValidSpells] = useState([]);
  const [usedSpells, setUsedSpells] = useState([]);
  const timerRef = useRef(null);

  useEffect(() => {
    if (spells && spells.length > 0) {
      const filtered = spells.filter(spell => spell.duration && spell.drCategory);
      setValidSpells(filtered);
    }
  }, [spells]);

  useEffect(() => {
    if (gameStarted && !gameOver && !answerSubmitted) {
      timerRef.current = setInterval(() => {
        setTimeLeft(prev => {
          if (prev <= 100) {
            clearInterval(timerRef.current);
            handleTimeout();
            return 0;
          }
          return prev - 100;
        });
      }, 100);

      return () => clearInterval(timerRef.current);
    }
  }, [gameStarted, gameOver, answerSubmitted]);

  const startNewRound = () => {
    if (validSpells.length > usedSpells.length && !showingFinalFeedback) {
      let availableSpells = validSpells.filter(spell => !usedSpells.includes(spell.id));
      const randomSpell = availableSpells[Math.floor(Math.random() * availableSpells.length)];
      setCurrentSpell(randomSpell);
      setUsedSpells(prev => [...prev, randomSpell.id]);
      setCurrentQuestion(Math.random() < 0.5 ? 'dr' : 'duration');
      setTimeLeft(MAX_ROUND_DURATION);
      setSelectedAnswer('');
      setFeedback(null);
      setAnswerSubmitted(false);
    } else if (showingFinalFeedback) {
      setGameOver(true);
    }
  };

  const handleAnswer = (event) => {
    if (answerSubmitted) return; // Prevent multiple submissions

    clearInterval(timerRef.current); // Clear the timer
    setAnswerSubmitted(true);

    const answer = event.target.value;
    setSelectedAnswer(answer);

    const isCorrect = (currentQuestion === 'dr' && answer === currentSpell.drCategory) ||
                      (currentQuestion === 'duration' && parseInt(answer) === currentSpell.duration);

    if (isCorrect) {
      const timeTaken = MAX_ROUND_DURATION - timeLeft;
      const pointsEarned = Math.round((1 - timeTaken / MAX_ROUND_DURATION) * 1000);
      setScore(prev => prev + pointsEarned);
      setFeedback({
        type: 'success',
        message: `Correct! You earned ${pointsEarned} points.`
      });
    } else {
      setWrongAnswers(prev => prev + 1);
      const correctAnswer = currentQuestion === 'dr' ? currentSpell.drCategory : `${currentSpell.duration}s`;
      setFeedback({
        type: 'error',
        message: `Incorrect. The correct answer is: ${correctAnswer}`
      });
    }

    setRoundsPlayed(prev => {
      const nextRound = prev + 1;
      if (nextRound >= MAX_ROUNDS || (isCorrect ? wrongAnswers : wrongAnswers + 1) >= MAX_WRONG_ANSWERS) {
        setShowingFinalFeedback(true);
        setTimeout(() => setGameOver(true), 3000); // Show game over after 3 seconds
      } else {
        setTimeout(startNewRound, 2000);
      }
      return nextRound;
    });
  };

  const handleTimeout = () => {
    if (answerSubmitted) return; // Prevent timeout handling if answer was submitted

    setAnswerSubmitted(true);
    setWrongAnswers(prev => prev + 1);
    setFeedback({
      type: 'error',
      message: "Time's up! No answer selected."
    });

    setRoundsPlayed(prev => {
      const nextRound = prev + 1;
      if (nextRound >= MAX_ROUNDS || wrongAnswers + 1 >= MAX_WRONG_ANSWERS) {
        setShowingFinalFeedback(true);
        setTimeout(() => setGameOver(true), 3000); // Show game over after 3 seconds
      } else {
        setTimeout(startNewRound, 2000);
      }
      return nextRound;
    });
  };

  const startGame = () => {
    if (validSpells.length > 0) {
      setGameStarted(true);
      resetGame();
    }
  };

  const resetGame = () => {
    setScore(0);
    setRoundsPlayed(0);
    setWrongAnswers(0);
    setGameOver(false);
    setShowingFinalFeedback(false);
    setCurrentSpell(null);
    setCurrentQuestion(null);
    setSelectedAnswer('');
    setFeedback(null);
    setAnswerSubmitted(false);
    setTimeLeft(MAX_ROUND_DURATION);
    setUsedSpells([]);
    clearInterval(timerRef.current);
    startNewRound();
  };

  const backToStart = () => {
    setGameStarted(false);
    resetGame();
  };

  if (validSpells.length === 0) {
    return <Typography>No valid spells available for the game.</Typography>;
  }

  return (
    <Box sx={{ padding: 4, maxWidth: 'sm', margin: '0 auto' }}>
      <Typography variant="h4" gutterBottom>
        Diminishing Return Training Game
      </Typography>

      {!gameStarted ? (
        <Button variant="contained" onClick={startGame}>Start Game</Button>
      ) : !gameOver ? (
        <>
          {currentSpell && (
            <GameCard>
              <CardContent>
                <Typography variant="h6" align="center" gutterBottom>
                  {currentSpell.class}
                </Typography>
                <SpellIcon
                  image={`https://wow.zamimg.com/images/wow/icons/large/${currentSpell.icon}.jpg`}
                  title={currentSpell.name}
                />
                <Typography variant="subtitle1" align="center" gutterBottom sx={{ mt: 1 }}>
                  {currentSpell.name}
                </Typography>
                
                <FormControl component="fieldset">
                  <FormLabel component="legend">
                    {currentQuestion === 'dr' ? 'Select DR Category' : 'Select Duration'}
                  </FormLabel>
                  <RadioGroup value={selectedAnswer} onChange={handleAnswer}>
                    {currentQuestion === 'dr' ? (
                      drCategories.map(category => (
                        <FormControlLabel key={category} value={category} control={<Radio />} label={category} disabled={answerSubmitted} />
                      ))
                    ) : (
                      [1, 2, 3, 4, 5, 6, 8].map(duration => (
                        <FormControlLabel key={duration} value={duration.toString()} control={<Radio />} label={`${duration}s`} disabled={answerSubmitted} />
                      ))
                    )}
                  </RadioGroup>
                </FormControl>
                {feedback && (
                  <Alert severity={feedback.type} sx={{ mt: 2 }}>
                    {feedback.message}
                  </Alert>
                )}
              </CardContent>
              <LinearProgress variant="determinate" value={(timeLeft / MAX_ROUND_DURATION) * 100} />
            </GameCard>
          )}

          <Typography variant="h6" sx={{ mt: 2 }}>
            Score: {score} | Round: {Math.min(roundsPlayed + 1, MAX_ROUNDS)}/{MAX_ROUNDS} | Wrong Answers: {wrongAnswers}/{MAX_WRONG_ANSWERS}
          </Typography>
          {showingFinalFeedback && (
            <Typography variant="h5" color="error" sx={{ mt: 2 }}>
              Game Over!
            </Typography>
          )}
          <Button variant="contained" onClick={resetGame} sx={{ mt: 2 }}>Restart Game</Button>
        </>
      ) : (
        <Box sx={{ mt: 4, textAlign: 'center' }}>
          <Typography variant="h5" gutterBottom>Game Over!</Typography>
          <Typography variant="h6" gutterBottom>Final Score: {score}</Typography>
          <Button variant="contained" onClick={startGame} sx={{ mr: 2 }}>Play Again</Button>
          <Button variant="outlined" onClick={backToStart}>Back to Start</Button>
        </Box>
      )}
    </Box>
  );
}