import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { AppBar, Toolbar, Button, Typography, Box, IconButton, Menu, MenuItem } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { styled } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

const WoWAppBar = styled(AppBar)(({ theme }) => ({
  background: 'linear-gradient(to bottom, #2c3e50, #34495e)',
  boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .3)',
}));

const WoWButton = styled(Button)(({ theme }) => ({
  color: '#ffd700',
  fontWeight: 'bold',
  margin: theme.spacing(0, 1),
  '&:hover': {
    backgroundColor: 'rgba(255, 215, 0, 0.1)',
  },
}));

const WoWTypography = styled(Typography)(({ theme }) => ({
  fontFamily: '"Cinzel", serif',
  color: '#ffd700',
  textShadow: '2px 2px 4px rgba(0,0,0,0.5)',
}));

const WoWMenuItem = styled(MenuItem)(({ theme }) => ({
  color: '#ffd700',
  fontWeight: 'bold',
  '&:hover': {
    backgroundColor: 'rgba(255, 215, 0, 0.1)',
  },
}));

const Navigation = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const menuItems = [
    { text: 'Home', path: '/' },
    { text: 'DR Matrix', path: '/dr-matrix' },
    { text: 'DR Game', path: '/dr-training' },
  ];

  return (
    <WoWAppBar position="static">
      <Toolbar>
        <WoWTypography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          GladPush.gg
        </WoWTypography>
        {isMobile ? (
          <div>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={handleMenu}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              {menuItems.map((item) => (
                <WoWMenuItem
                  key={item.text}
                  onClick={handleClose}
                  component={RouterLink}
                  to={item.path}
                >
                  {item.text}
                </WoWMenuItem>
              ))}
            </Menu>
          </div>
        ) : (
          <Box>
            {menuItems.map((item) => (
              <WoWButton key={item.text} component={RouterLink} to={item.path}>
                {item.text}
              </WoWButton>
            ))}
          </Box>
        )}
      </Toolbar>
    </WoWAppBar>
  );
};

export default Navigation;