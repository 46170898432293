import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Typography, Card, CardMedia, CardContent, Grid, Link } from '@mui/material';
import { styled } from '@mui/material/styles';

const WoWTypography = styled(Typography)(({ theme }) => ({
  fontFamily: '"Cinzel", serif',
  color: '#ffd700',
  textShadow: '2px 2px 4px rgba(0,0,0,0.5)',
}));

const WoWCard = styled(Card)(({ theme }) => ({
  background: 'linear-gradient(to bottom, #2c3e50, #34495e)',
  border: '2px solid #ffd700',
  borderRadius: '10px',
  overflow: 'hidden',
  transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
  '&:hover': {
    transform: 'scale(1.05)',
    boxShadow: '0 10px 20px rgba(0,0,0,0.4)',
  },
  position: 'relative',
  height: '100%',
}));

const WoWCardMedia = styled(CardMedia)(({ theme }) => ({
  height: 0,
  paddingTop: '56.25%', // 16:9 aspect ratio
}));

const WoWCardContent = styled(CardContent)(({ theme }) => ({
  position: 'absolute',
  bottom: 0,
  left: 0,
  right: 0,
  background: 'rgba(0,0,0,0.7)',
  padding: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start', // Align text to the left
  justifyContent: 'flex-end', // Align content to the bottom
}));

const CardTitle = styled(Typography)(({ theme }) => ({
  fontFamily: '"Cinzel", serif',
  color: '#ffd700',
  textShadow: '2px 2px 4px rgba(0,0,0,0.7)',
  fontSize: '1.8rem',
  fontWeight: 'bold',
  marginBottom: theme.spacing(1),
}));

const CardSubtitle = styled(Typography)(({ theme }) => ({
  fontFamily: '"Cinzel", serif',
  color: '#ffffff',
  fontSize: '1rem',
  lineHeight: 1.2,
}));

const Disclaimer = styled(Typography)(({ theme }) => ({
  fontFamily: '"Cinzel", serif',
  color: '#ffd700',
  fontSize: '0.8rem',
  textAlign: 'center',
  marginTop: theme.spacing(4),
}));

const Home = () => {
  return (
    <Box sx={{ padding: 4 }}>
      <WoWTypography variant="h2" align="center" gutterBottom>
        Welcome to GladPush.gg
      </WoWTypography>
      <Grid container spacing={4} justifyContent="center">
        <Grid item xs={12} sm={6}>
          <WoWCard component={RouterLink} to="/dr-matrix">
            <WoWCardMedia
              image="/mage_matrix.png"
              title="DR Matrix"
            />
            <WoWCardContent>
              <CardTitle>
                DR Matrix
              </CardTitle>
              <CardSubtitle>
                Review Diminishing Returns for your 2v2 or 3v3 team comp
              </CardSubtitle>
            </WoWCardContent>
          </WoWCard>
        </Grid>
        <Grid item xs={12} sm={6}>
          <WoWCard component={RouterLink} to="/dr-training">
            <WoWCardMedia
              image="/orcs_game.png"
              title="DR Game"
            />
            <WoWCardContent>
              <CardTitle>
                DR Game
              </CardTitle>
              <CardSubtitle>
                Test Your Knowledge
              </CardSubtitle>
            </WoWCardContent>
          </WoWCard>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Home;